import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Typography, Container } from "@material-ui/core"
import SEO from "./seo"
import { useUtilStyles } from "./utils"

type PageProps = {
  data: {
    page: {
      title: string
      slug: string
      excerpt: string
      body: string
    }
  }
}

const Page = ({ data: { page } }: PageProps) => (
  <>
    <SEO title={page.title} description={page.excerpt} />
    <Container maxWidth="md" className={useUtilStyles().topSpacer}>
    <h1 style={{ textAlign: 'center',textTransform: 'uppercase',fontSize:'2.75rem',fontFamily:'Aleo, Serif',fontWeight:500,lineHeight:1.5 }}>
      {page.title}
      </h1>
      <section style={{ marginTop: "2rem" }}>
        <MDXRenderer>{page.body}</MDXRenderer>
      </section>
    </Container>
  </>
)

export default Page
